import React from "react";
import { Link } from "react-router-dom";
import { ASSET_STATUS } from "src/constants";
import { currentWorkspaceHipaa, formatBytes, secondsToHMS } from "src/utils";
import { RecordingLinkButton } from "./RecordingLinkButton";
import {
  customComparatorForRecordingMediaTypes,
  getAssetButtonLabel,
  getAssetMediaType,
} from "../../helpers";
import { recordingAsset } from "../../types";

const headers = [
  {
    title: "Peer ID",
    gridCols: "col-span-3",
  },
  {
    title: "Media Type",
    gridCols: "col-span-2",
  },
  {
    title: "Size",
    gridCols: "col-span-1",
  },
  {
    title: "Duration",
    gridCols: "col-span-1",
  },
  {
    title: "Asset URL",
    gridCols: "col-span-2",
  },
];

type Props = {
  recordingAssets: recordingAsset[];
  setAssetLabel: (assetType: string) => void;
  fetchPresignedUrl: ({
    roomId,
    assetId,
    data,
    download,
    setLoading,
  }: {
    setLoading: (value: boolean) => void;
    roomId: string;
    assetId: string;
    data: string;
    download?: boolean;
  }) => void;
};

export default function AssetTable({
  recordingAssets,
  fetchPresignedUrl,
  setAssetLabel,
}: Props) {
  const isHipaa = currentWorkspaceHipaa();
  const hasPeerIds = recordingAssets.some(item => !!item?.metadata?.peer_id);
  const applicableHeaders = headers.filter(
    header =>
      !(
        (isHipaa && header.title === "Asset URL") ||
        (!hasPeerIds && header.title === "Peer ID")
      )
  );
  const numGridCols = applicableHeaders.reduce((accum: number, header) => {
    return accum + parseInt(header.gridCols.replace("col-span-", ""));
  }, 0);
  const gridColsClass = `grid-cols-${numGridCols}`;

  return (
    <div>
      <div
        className={`py-4 w-full grid ${gridColsClass} px-2 gap-x-12 uppercase text-textIcon-medEmp text-xs leading-4 font-semibold tracking-widest border-b border-border-light`}
      >
        {applicableHeaders.map(header => {
          return <div className={header.gridCols}>{header.title}</div>;
        })}
      </div>
      {recordingAssets
        .sort(customComparatorForRecordingMediaTypes)
        .map((item, index) => (
          <div
            key={index}
            className={`grid ${gridColsClass} items-center px-2 py-4 gap-x-12 hover:bg-surface-light rounded-xs`}
          >
            {/* Peer ID */}
            {hasPeerIds ? (
              <div className="col-span-3 truncate">
                {item?.metadata?.peer_id ? (
                  <Link
                    className="text-primary-light"
                    to={`/session-details/peer/${item.metadata.peer_id}`}
                  >
                    {item?.metadata?.peer_id}
                  </Link>
                ) : (
                  "-"
                )}
              </div>
            ) : null}

            {/* Media Type */}
            <div className="col-span-2">
              {getAssetMediaType({ asset: item }) || "-"}
            </div>

            {/* Size */}
            <div className="col-span-1">
              {typeof item?.size === "number" && item?.size >= 0
                ? formatBytes(item?.size)
                : "-"}
            </div>

            {/* Duration */}
            <div className="col-span-1">
              {item?.duration ? secondsToHMS(item?.duration) : "-"}
            </div>

            {/* Asset URL */}
            <div
              className="col-span-2"
              style={{ display: isHipaa ? "none" : "" }}
            >
              <RecordingLinkButton
                fetchPresignedUrl={fetchPresignedUrl}
                data={item?.path}
                roomId={item?.room_id}
                assetId={item?.id}
                label={getAssetButtonLabel(item)}
                setAssetLabel={setAssetLabel}
                disabled={item.status !== ASSET_STATUS.COMPLETED}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
